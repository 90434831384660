import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function CarouselS({ img, thumb = false }) {
  return (
    <>
      <Carousel showIndicators={true} showThumbs={thumb} showStatus={false}>
        {img.map((imge, i) => (
          <div key={i}>
            <img className="d-block w-100" src={imge} alt="Second slide" />
          </div>
        ))}
      </Carousel>
    </>
  );
}

export default CarouselS;
