import React from "react";
import SCard from "../components/SCard";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../components/Loading";

function User({ uid, shop }) {
  const [user, setUser] = useState();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .post(
        "https://shopco.onrender.com/getuser",
        { user_id: id },
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      )
      .then((data) => setUser(data.data.user));
    setLoading(true);
  }, [id, loading]);
  return (
    <div className="p-cont">
      <div>
        {" "}
        {user ? (
          <div className="container">
            <div className="profile">
              <div className="user-details">
                <div>
                  <img src={user.picture} alt="" />
                </div>
                <div>
                  <h3>{user.name}</h3>
                  <h5>{user.email}</h5>
                </div>
              </div>
              {user._id === uid ? (
                <div className="user-buttons">
                  <Link to={"/add-shop"}>
                    <button className="add-btn">
                      <i className="bi bi-plus-circle-dotted"></i> Create link
                    </button>
                  </Link>
                  <Link>
                    <button disabled="disabled" className="add-btn">
                      <i className="bi bi-gear-wide-connected"></i> Settings
                    </button>
                  </Link>
                </div>
              ) : null}

              <div>
                <div className="post-line">
                  <h6>Shops </h6>
                </div>
              </div>

              <div className="shop-display">
                {shop.map((shopu, index) => (
                  <div key={index}>
                    {shopu.user_id === user._id ? (
                      <SCard
                        shopu={shopu}
                        key={index}
                        showeditbtn={user._id === uid ? true : false}
                      />
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
}

export default User;
