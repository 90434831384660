import React, { useState } from "react";
import axios from "axios";
import SCard from "../components/SCard";
import Search from "../components/Search";
import ECard from "../components/ECard";

function Home({ shop, user, loading }) {
  const [name, setName] = useState();
  const [sshop, setSshop] = useState();

  const search = () => {
    axios
      .post(
        "https://shopco.onrender.com/search",
        { name },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((data) => setSshop(data.data.sshop));
  };
  return (
    <div className="home">
      <div className="search-cont">
        <Search onChange={(e) => setName(e.target.value)} onClick={search} />
      </div>
      <div className="container">
     
      
        <div className="shop-display">
        <div id="container-3e6336df551c422d0f8b494d5189c31b" className="ad"></div>
          {sshop ? (
            <>
              {sshop.length <= 0 ? (
                <ECard errm={"No Results found. "} sbtn={false} black={false} />
              ) : (
                <>
                  {" "}
                  {sshop.map((shopu, index) => (
                    <SCard shopu={shopu} key={index} />
                  ))}
                </>
              )}
            </>
          ) : (
            <>
              {shop.map((shopu, index) => (
                <div key={index}>
                  {user ? (
                    <>
                      {shopu.user_id === user._id ? null : (
                        <>
                          {" "}
                          {loading ? (
                            <div className="loader"></div>
                          ) : (
                            <SCard shopu={shopu} key={index} />
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {" "}
                      {index === 3 && (
                        <>
                          {" "}
                          <div id="container-3e6336df551c422d0f8b494d5189c31b"></div>
                        </>
                      )}
                      <SCard shopu={shopu} key={index} />
                    </>
                  )}
                </div>
              ))}
            </>
          )}
          
        </div>
      </div>
     
    </div>
  );
}

export default Home;
