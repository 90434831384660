import React from 'react'

function Loading() {
  return (
    <div className="loader-c">
    <div className="loader-line"></div>
  </div>
  )
}

export default Loading