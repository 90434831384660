import React from "react";
import CarouselS from "../components/CarouselS";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

function Shop({ user }) {
  const [shop, setShop] = useState();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .post(
        "https://shopco.onrender.com/viewshop",
        { shop_id: id },
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      )
      .then((data) => setShop(data.data.shop));
    setLoading(true);
  }, [id, loading]);

  return (
    <>
      <div className="shop-container">
        {!shop ? null : (
          <>
            <img className="background" src={shop.img[0]} alt="Aleq" />
            <div className="shop-card">
              <div>
                <div className="shop-details">
                  <h2>{shop.shop_name}</h2>
                </div>

                <div className="sc-btn">
                  {shop.links &&
                    shop.links.map((button) => (
                      <div>
                        <a
                          href={button.link}
                          className={`acc-btn`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <button className={` ${button.media}`}>
                            <i className={`bi bi-${button.media}`}></i>
                            &nbsp;{button.title}
                          </button>
                        </a>
                      </div>
                    ))}
                </div>
                <hr />
                <div className=" container">
                  <Link to={`/profile/${shop.user_id}`} className="profile-btn">
                    <button className="shop-owner">
                      <img src={shop.userimg} alt="" />
                      {shop.username}
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="shop-desc">
              <h3>About</h3>
              <h5> {shop.desc}</h5>
              {!user && <div className="message">Login to create a link.</div>}
            </div>

            <div className="gallery">
              <CarouselS img={shop.img} />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Shop;
