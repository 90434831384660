import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import Loading from "../components/Loading";

function Edit({ user }) {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const remove = async () => {
    setLoading(true);
    try {
      let d = await axios.post(
        "https://shopco.onrender.com/delete",
        { shop_id: id },
        {
          headers: {
            token: localStorage.getItem("token"),
            "Content-Type": `application/json`,
          },
        }
      );
      if (d) {
        window.location.href = `/profile/${user._id}`;
      }
      return d;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container">
      {user && user.shops.includes(id) ? (
        <div className="add-form">
          <div className="alert-c">
            <div>Do you want to delete {id}. </div>
            <button onClick={remove} disabled={loading}>
              Remove
            </button>
          </div>
          <div className="message">
            Site under construction. Edit option will be available later.
            <a href="https://wa.me/9495314108" rel="noreff" target="blant">
              {" "}
              Contact admin.
            </a>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default Edit;