import React from "react";

function Search({ onChange, onClick }) {
  return (
    <div className="search ">
      <button>
        <i className="bi bi-binoculars"></i>
      </button>
      <input type="text" placeholder="Search " onChange={onChange} />
      <button onClick={onClick}>
        {" "}
        <i className="bi bi-arrow-right"></i>
      </button>
    </div>
  );
}

export default Search;
