import { Link } from "react-router-dom";
import Logo from "../logo.png";

function AppHeader({ user }) {
  const login = () => {
    window.location.href = "https://shopco.onrender.com/auth/google";
  };

  return (
    <div className="navbar">
      <div className="container">
        <div>
          <Link to="/" className="brand">
            <img src={Logo} alt="" width={60} />
          </Link>
        </div>
        <div />
        {!user ? (
          <div className="justify-content-end">
            <button className="btn login-btn" onClick={login}>
              Login
            </button>
          </div>
        ) : (
          <div className="justify-content-end">
            {user && window.location.pathname === `/profile/${user._id}` ? (
              <button
                className="profile-btn btn"
                onClick={() => {
                  localStorage.removeItem("token");
                  window.location.href = "/";
                }}
              >
                Logout
                <i className="bi bi-box-arrow-right"></i>
              </button>
            ) : (
              <Link to={`/profile/${user._id}`} className="profile-btn">
                <button className="btn ">
                  <img src={user.picture} alt="" />
                  {user.name}
                  <i className="bi bi-arrow-right"></i>
                </button>
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default AppHeader;
