import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import User from "./pages/User";
import Shop from "./pages/Shop";
import axios from "axios";
import { useEffect, useState } from "react";
import AppHeader from "./components/AppHeader";
import Add from "./pages/Add";
import ECard from "./components/ECard";
import Edit from "./pages/Edit";

function App() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const [shop, setShop] = useState([]);

  const queryParameters = new URLSearchParams(window.location.search);
  useEffect(() => {
    if (queryParameters.get("token")) {
      localStorage.setItem("token", queryParameters.get("token"));
    }

    axios
      .post(
        "https://shopco.onrender.com/checkuser",
        {},
        { headers: { token: localStorage.getItem("token") } }
      )
      .then((data) => {
        setLoading(false);
        setUser(data.data.user);
      });
    axios.get("https://shopco.onrender.com").then((data) => {
      setShop(data.data.shop);
    });
    if (user) {
      window.location.href = "/";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <AppHeader user={user} />

      <Routes>
        <Route
          path="/"
          element={
            <Home shop={shop} user={user ? user : null} loading={loading} />
          }
        />
        <Route
          path="/profile/:id"
          element={
            user ? (
              <User uid={user._id} shop={shop} />
            ) : (
              <ECard errm={"Login to see user. "} />
            )
          }
        />
        <Route path="/:id" element={<Shop user={user} />} />
        <Route path="/add-shop/" element={<Add user={user} />} />
        <Route path="/settings/:id" element={<Edit user={user} />} />
        <Route path="*" element={<ECard errm={"Page not found"} />} />
      </Routes>
    </div>
  );
}

export default App;
