import React, { useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function SCard({ shopu, key, showeditbtn = false }) {
  const [loadimg, setLoadimg] = useState(true);
  const [h, seth] = useState(0);
  return (
    <Link to={`/${shopu.usid}`} key={key}>
      {showeditbtn && (
        <Link to={`/settings/${shopu._id}`} className="add-btn edit-btn">
          <button>
            <i className="bi bi-gear-wide-connected"></i> Settings
          </button>
        </Link>
      )}{" "}
      <div className="card">
        {loadimg && <Skeleton height={210} />}

        <img
          alt="losding"
          className="card-img"
          src={shopu.img[0]}
          height={h}
          onLoad={() => {
            setLoadimg(false);
            seth(200);
          }}
        />

        <div className="card-b">
          <div className="card-t">{shopu.shop_name} </div>
          <div className="card-d">{shopu.desc}</div>
        </div>
      </div>
    </Link>
  );
}

export default SCard;
