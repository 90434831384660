import axios from "axios";
import React, { useState } from "react";
import Loading from "../components/Loading";
import CarouselS from "../components/CarouselS";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";

function Add({ user }) {
  const [images, setImages] = useState([]);
  const [shopaddress, setShopaddress] = useState();
  const [accs, setAccs] = useState([]);
  const [title, setTitle] = useState();
  const [shop_name, setShop_name] = useState();
  const [desc, setDesc] = useState();
  const [phone, setPhone] = useState(false);
  const [email, setEmail] = useState(false);
  const [whatsapp, setWp] = useState(false);
  const [whatsapp_grp, setWg] = useState(false);
  const [loaction, setLoc] = useState(false);
  const [telegram_grp, setTg] = useState(false);
  const [youtube, setYt] = useState(false);
  const [facebook, setFb] = useState(false);
  const [instagram, setIns] = useState(false);
  const [threads, setThr] = useState(false);
  const [twiter, setTw] = useState(false);
  const [bdis, setBdis] = useState(false);
  const [addbtn, setAddbtn] = useState([]);
  const [dropdown, setDropdown] = useState(false);
  const [result, setResult] = useState("");
  const [err, setErr] = useState(false);

  const links = [
    {
      name: "Phone",
      click: setPhone,
      var: phone,
      nvar: "phone",
      icon: "bi bi-telephone",
    },
    {
      name: "Email",
      click: setEmail,
      var: email,
      nvar: "email",
      icon: "bi bi-envelope",
    },
    {
      name: "Whatsapp",
      click: setWp,
      var: whatsapp,
      nvar: "whatsapp",
      icon: "bi bi-whatsapp",
    },
    {
      name: "Whatsapp Group",
      click: setWg,
      var: whatsapp_grp,
      nvar: "whatsapp_grp",
      icon: "bi bi-whatsapp",
    },
    {
      name: "Google map",
      click: setLoc,
      var: loaction,
      nvar: "loaction",
      icon: "bi bi-geo",
    },
    {
      name: "Twitter",
      click: setTw,
      var: twiter,
      nvar: "twitter",
      icon: "bi bi-twitter",
    },
    {
      name: "Telegram Group",
      click: setTg,
      var: telegram_grp,
      nvar: "telegram_grp",
      icon: "bi bi-telegram",
    },
    {
      name: "Youtube",
      click: setYt,
      var: youtube,
      nvar: "youtube",
      icon: "bi bi-youtube",
    },
    {
      name: "Facebook",
      click: setFb,
      var: facebook,
      nvar: "facebook",
      icon: "bi bi-facebook",
    },
    {
      name: "Instagram",
      click: setIns,
      var: instagram,
      nvar: "instagram",
      icon: "bi bi-instagram",
    },
    {
      name: "Threads",
      click: setThr,
      var: threads,
      nvar: "threads",
      icon: "bi bi-at",
    },
  ];

  let fdata = new FormData();

  for (let i = 0; i < images.length; i++) {
    fdata.append("image", images[i]);
  }

  const addshop = async () => {
    setBdis(true);
    fdata.append("shop_name", shop_name);
    fdata.append("desc", desc);
    fdata.append("usid", shopaddress);
    for (let i = 0; i < accs.length; i++) {
    if(accs[i].media === 'phone'){
      accs.link = 'tel:'+phone
    }
    if(accs[i].media === 'email'){
      accs.link='mailto:'+email
    }
    }
    
    fdata.append("linksa", JSON.stringify(accs));

    try {
      let d = await axios.post(
        "https://shopco.onrender.com/createshop",
        fdata,
        {
          headers: {
            token: localStorage.getItem("token"),
            "Content-Type": `multipart/form-data`,
          },
        }
      );
      if (d) {
        if (d.data.err) {
          setErr(d.data.err);
          setBdis(false);
          setShopaddress(undefined);
        } else {
          window.location.href = `/${d.data.createdshop.usid}`;
        }
      }
      return d;
    } catch (error) {
      console.log(error);
    }
  };
  const uploader = (e) => {
    const selectedFIles = [];
    const targetFiles = e.target.files;
    const targetFilesObject = [...targetFiles];
    targetFilesObject.map((file) => {
      return selectedFIles.push(URL.createObjectURL(file));
    });
    setResult(selectedFIles);
  };
  const shopadbar = (e) => {
    if (e.target.value.match(/\W/) || /\s/g.test(e.target.value)) {
      setErr("Id should not contain special characters or spaces");
    } else {
      setErr(false);

      setShopaddress(e.target.value.toLowerCase());
    }
  };

  return (
    <div className="container">
      {user ? (
        <div className="add-form">
          <div className="basic-details">
            {!err ? (
              <div className="no-alert">
                {" "}
                /{shopaddress ? shopaddress : "enteridforshop"}
              </div>
            ) : (
              <div className="alert-c">{err}</div>
            )}

            <input
              type="text"
              pattern="[^\s]+"
              style={{ textTransform: "lowercase" }}
              onChange={(e) => {
                shopadbar(e);
              }}
              placeholder="Enter a unique shop id."
              id="sp_ad"
            />

            <input
              type="text"
              onChange={(e) => setShop_name(e.target.value)}
              placeholder="Shop name"
            />

            <input
              type="text"
              onChange={(e) => setDesc(e.target.value)}
              placeholder="About shop"
            />
            <input
              type="file"
              multiple
              onChange={(e) => {
                setImages(e.target.files);
                uploader(e);
              }}
              id="files"
            />
            <label>Add Minimum 2 images.</label>
            {result && (
              <div className="preview">
                <CarouselS img={result} thumb={true} />
              </div>
            )}
          </div>
          <div className="add-drop">
            <button className="add-link-btn" onClick={() => setDropdown(true)}>
              {" "}
              <i className="bi bi-plus-circle-dotted"></i> Add link
            </button>
            <BottomSheet
              open={dropdown}
              onDismiss={() => setDropdown(false)}
              defaultSnap={({ maxHeight }) => maxHeight / 2}
              snapPoints={({ maxHeight }) => [
                maxHeight - maxHeight / 10,
                maxHeight / 4,
                maxHeight * 0.6,
              ]}
            >
              <div className="container">
                <div className="add-link">
                  {links.map((link, index) => (
                    <div key={index}>
                      <button
                        onClick={() => link.click(true)}
                        className={link.var === true && "link-added"}
                      >
                        <i
                          className={
                            link.var === true
                              ? "bi bi-check2-circle "
                              : link.icon
                          }
                        ></i>
                        &nbsp;{link.name}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </BottomSheet>
          </div>

          {links.map((link, index) => (
            <div key={index}>
              {" "}
              {link.var === true || link.var.length >= 0 ? (
                <div className="input-row">
                  <div>
                    <input
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder={`Title for ${link.name}`}
                    />
                  </div>
                  <div>
                    <input
                      onChange={(e) => {
                        link.click(e.target.value);
                      }}
                      placeholder={link.name}
                    />
                  </div>
                  <div className="add-input-btn">
                    <button
                      onClick={() => {
                        setAddbtn((products) =>
                          products.filter((_, index) => index !== 0)
                        );
                        setAccs((products) =>
                          products.filter((_, index) => index !== 0)
                        );

                        link.click(false);
                      }}
                    >
                      <i className="bi bi-trash3"></i>
                      &nbsp;Remove link
                    </button>
                    <button
                      onClick={() => {
                        setAccs((o) => [
                          {
                            link: link.var,
                            title: title,
                            media: link.nvar,
                          },
                          ...o,
                        ]);
                        setAddbtn((o) => [index, ...o]);
                      }}
                    >
                      <i
                        className={
                          addbtn.includes(index)
                            ? "bi bi-check2-circle "
                            : "bi bi-plus-circle-fill"
                        }
                      ></i>
                      &nbsp;{addbtn.includes(index) ? "Added" : "Add link"}
                    </button>
                  </div>
                  <div className="message">Click "add link" to add a link</div>
                </div>
              ) : null}
            </div>
          ))}

          <button
            onClick={() => {
              addshop();
            }}
            disabled={
              !shop_name ||
              !desc ||
              images.length < 2 ||
              !shopaddress ||
              err ||
              addbtn.length < 0 ||
              bdis
            }
          >
            {bdis && (
              <img src="https://i.gifer.com/ZZ5H.gif" width={16} alt="" />
            )}{" "}
            submit
          </button>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default Add;
