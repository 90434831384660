import React from "react";
import { Link } from "react-router-dom";

function ECard({ errm, sbtn = true, black = true }) {
  return (
    <div className={black ? "ecard " : "whitebg"}>
      <div className="container">
        <div className="ecard-msg">
          <h3>{errm}</h3>
          {sbtn && (
            <Link to={"/"}>
              <button className="add-btn">Go to Homepage.</button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default ECard;
